<template>
    <div>
        <table width="100%" class="vs-table vs-table--tbody-table">
            <tr>
                <td><img :src="logo" height="55" class="mr-5"></td>
                <td><b>Pengajuan Penghapusan Inventory</b></td>
            </tr>
        </table>
        <br>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Cabang
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.territory.name}}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Reference No.
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.scrap.Code}}
                </td>
            </tr> 
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Kode Cabang
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.territory.code}}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Periode
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatMonth(this.data.scrap.Date)}}
                </td>
            </tr>
        </table>
  

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th width="20%" rowspan="2" class="text-center">Storage Location</th>
                    <th width="20%" rowspan="2" class="text-center">Detail Brand</th>
                    <th width="20%" colspan="2" class="text-center">Actual Stock</th>
                    <th width="20%" colspan="2" class="text-center">System Stock</th>
                    <th width="5%" rowspan="2" class="text-center">Adjustment Quantity</th>
                    <th width="5%" rowspan="2" class="text-center">Adjustment Value</th>
                    <th width="10%" rowspan="2" class="text-center">Keterangan</th>
                </tr>
                <tr>
                    <th class="text-center">Sum Of Quantity</th>
                    <th class="text-center">Sum Of Value</th>
                    <th class="text-center">Sum Of Quantity</th>
                    <th class="text-center">Sum Of Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in this.data.scrap_line" :key="index" style="padding-bottom: 5px">
                    
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.StorageName }} 
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.ItemCode }} - {{ item.ItemName }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.Quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.Quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.Reason }}
                    </td>
                </tr>
            </tbody>
        </table>

        
        <table width="100%" class="vs-table vs-table--tbody-table print">
            <tr>
                <td>Keterangan : <br><br><br></td>
            </tr>
        </table>

        <table style="width:100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th width="30%" colspan="3" class="text-center">Diajukan Oleh</th>
                    <th width="70%" colspan="7" class="text-center">Disetujui Oleh</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">PIC Vendor/BLC/OS<br><br><br><br></td>
                    <td class="text-center">BAM/BAS<br><br><br><br></td>
                    <td class="text-center">BM/OM<br><br><br><br></td>
                    <td class="text-center">RLH<br><br><br><br></td>
                    <td class="text-center">RM<br><br><br><br></td>
                    <td class="text-center">RFAM<br><br><br><br></td>
                    <td class="text-center">Operation Excellence Manager<br><br><br><br></td>
                    <td class="text-center">Head of Logistic Operations<br><br><br><br></td>
                    <td class="text-center">Financial Business Partner<br><br><br><br></td>
                    <td class="text-center">CFO<br><br><br><br></td>
                </tr>
                <tr>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                    <td>Tanggal :</td>
                </tr>
            </tbody>
        </table>
        
        <br>
        <div>
            Note : <br>
            - Wajib melampirkan detail lampiran produk dan foto kondisi produk yang akan diajukan penghapusan inventory
        </div>

        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button style="margin:5px" color="primary" @click="handlePrint()">Print</vs-button>
            <vs-button style="margin:5px" color="danger" @click="handleBack()">Back</vs-button>
        </div>
    </div>
  </template>
  
  <script>
  import logo from "@/assets/images/logo/logo-sdn.png"
  import moment from "moment";
  export default {
    components: {
    },
    mounted() {
        this.id = parseInt(this.$route.query.id);
        this.initData();
    },
    data() {
        return {
            logo: logo,
            id: null,
            data: [],
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: "stock-scrap",
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$http
                .get("/api/wms/v1/scrap/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log(resp.data);
                          this.data = resp.data
                          this.$vs.loading.close();
                      } else {
                          this.$vs.loading.close();
                      }
                });
        },
    },
    computed: {
          formatDate: () => {
              return (val) => {
                  const momentDate = moment.utc(val);
                  return momentDate.format('DD MMM YYYY');
              };
          },
          formatMonth: () => {
              return (val) => {
                  const momentDate = moment.utc(val);
                  return momentDate.format('MMM YYYY');
              };
          },
      },
  }
  </script>
  <style>
  /* Set the page size to A4 and the orientation to landscape */
  @page {
    size: A4 landscape;
  }
  
  /* Set the font size to 12px for printing */
  @media print {
    body {
        font-size: 12px;
        background: none !important;
    }
  
    * {
        background: none !important;
    }
  
    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }
  
    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
  }
  
  table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table.print td,
  table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
  }
  </style>
  